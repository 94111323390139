.login-wrapper {
	text-align: center;
	padding: 32px 16px;
}
.login-wrapper img {
	width: 128px;
	height: auto;
}
.login-wrapper button {
	appearance: none;
	border: none;
	outline: none;
	background-color: var(--accent);
	border-radius: 8px;
	padding: 12px 24px;
	width: calc(100% - 16px);
	max-width: 300px;
	margin: 0 0 8px;
	font-size: 14px;
	color: var(--background);
	font-weight: bold;
	transition-duration: .2s;
}
.login-wrapper button:hover {
	opacity: .8;
	cursor: pointer;
	transition-duration: .2s;
}