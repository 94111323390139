.bg-gradient {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 240px;
	overflow: hidden;
}
.bg-gradient video {
	position: relative;
	width: 100%;
	height: auto;
}
.bg-gradient img {
	position: absolute;
    /*bottom: 0;*/
    bottom: 0%;
    left: 0;
    width: 100%;
    /*height: auto;*/
    height: 100%;
}

/*  */

.section-header {
	margin: 16px 0;
}
.section-header * {
	margin: 0;
}
.section-header.single-line {
	/* nothing */
}
.section-header.multi-line {
	display: flex;
	gap: 16px;
	padding-top: 24px;
}
.section-header.multi-line h4 {
	color: var(--grey);
	margin: 4px 0;
	font-weight: 500;
}
.section-header img {
	border-radius: 100%;
	width: 64px;
	height: 64px;
}

.card-wrapper {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
	gap: 16px;
}
.card-wrapper.overflow--snap-scroll {
	display: flex;
	grid-template-columns: unset;
	gap: 16px;
	width: auto;
	max-width: none;
	overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    scrollbar-width: none;
	margin: 0 -16px;
	padding: 0 16px;
}
.card-wrapper.overflow--snap-scroll::-webkit-scrollbar {
	display: none;
}
.card-wrapper.overflow--snap-scroll > div {
	/*scroll-snap-align: start;*/
	min-width: 144px;
}
.card {
	background-color: var(--foreground);
	border-radius: 8px;
	box-shadow: var(--shadow);
}
.recent-playlists {
	/*  */
}
.recent-playlist {
	display: flex;
	flex-wrap: nowrap;
	gap: 8px;
	height: 56px;
	overflow: hidden;
	transition-duration: .2s;
	text-decoration: none !important;
    color: white !important;
}
.recent-playlist:hover {
	cursor: pointer;
	opacity: .8;
	transition-duration: .2s;
}
.recent-playlist img {
	width: 56px;
	height: 56px;
}
.recent-playlist span {
	font-size: 14px;
	line-height: 56px;
	/*font-family: 'Circular Spotify Bold';*/
	font-weight: bold;
}
.recommended-albums {
	/*  */
}
.recommended-album {
	width: 144px;
	max-width: 144px;
	transition-duration: .2s;
}
.recommended-album:hover {
	cursor: pointer;
	opacity: .8;
	transition-duration: .2s;
}
.recommended-album img {
	/*width: 112px;*/
	/*height: 112px;*/
	width: auto;
	max-width: 100%;
	height: auto;
	margin-bottom: 12px;
}
.recommended-album span {
	font-weight: bold;
}

.info-section{
	padding: 16px;
	width: 100%;
	height: auto;
}
.info-section span{
	font-size: 14px;
	line-height: 20px;
	color: var(--grey);
}

.scram-message {
	width: 100%;
	max-width: 380px;
	position: relative;
    margin: 0 16px;
    width: calc(100vw - 32px);
}

.scram-message button {
	appearance: none;
	border: none;
	outline: none;
	background-color: var(--accent);
	border-radius: 8px;
	padding: 12px 24px;
	width: 100%;
	max-width: 100%;
	margin: 0;
	font-size: 14px;
	color: var(--background);
	font-weight: bold;
	transition-duration: .2s;
}
button:hover {
	opacity: .8;
	cursor: pointer;
	transition-duration: .2s;
}

.scram-message img {
	width: 100%;
	max-width: 100%;
	margin-bottom: 16px;
}

.home-header-content {
	display: flex;
	flex-wrap: nowrap;
	gap: 8px
}
.home-header-content > * {
	padding: 0px 16px;
	line-height: 32px;
	/*  */
	border-radius: 32px;
	background-color: var(--foreground);
	color: var(--white);
	font-size: 12px;
	font-weight: 600;
}
.home-header-content > .active {
	/*background-color: var(--accent);*/
	background-color: #1ED760;
	color: var(--white);
}
.home-header-content .user-icon {
	padding: 0;
	line-height: 32px;
	width: 32px;
	height: 32px;
	font-size: 14px;
	text-align: center;
	border-radius: 32px;
	color: var(--background);
	background-color: #19E68C;
	/*background-color: var(--accent);*/
}

footer .play-controller::before {
	position: absolute;
	content: "";
    top: -32px;
	z-index: -1;
	height: 88px;
	width: calc(100% + 16px);
	background: linear-gradient(0deg, #121212 20%, rgba(120, 120, 120, 0.00) 100%);
}
footer .play-controller {
	position: absolute;
    top: -56px;
	width: calc(100% - 16px);
	background-color: #232853;
	border-radius: 8px;
}

footer .play-controller .details {
	display: flex;
	gap: 8px;
	padding: 8px;
}
footer .play-controller .details img {
	width: 40px;
	border-radius: 4px;
}
footer .play-controller .details .text span {
	display: block;
	margin: 2px 0;
}
footer .play-controller .details .text span:last-of-type {
	color: var(--grey);
	font-size: 12px;
	margin: 0;
}
footer .play-controller .scrub-bar {
	width: calc(100% - 16px);
	margin: -3px 9px 0;
	height: 3px;
	border-radius: 3px;
	background-color: #ffffff60;
}
footer .play-controller .scrub-bar .scrub-bar-progress {
	width: 100px;
	height: 3px;
	border-radius: 3px;
	background-color: #ffffff;
}

footer .play-controller .details .controls {
	display: flex;
	gap: 4px;
	margin: 0 0 0 auto;
}
footer .play-controller .details .controls button {
	appearance: none;
	border: none;
	background-color: transparent;
	background-color: #FFFFFF20;
	width: 40px;
	height: 40px;
	border-radius: 4px;
	display: flex;
}
footer .play-controller .details .controls svg {
	height: 20px;
	margin: auto;
	fill: var(--white);
}
footer .play-controller .details .controls svg.synced {
	fill: var(--accent);
}
footer .play-controller .details .controls svg.failed {
	fill: red;
}