
:root{
	/* Set sans-serif & mono fonts */
	--sans-font: -apple-system, BlinkMacSystemFont, "Avenir Next", Avenir,
		"Nimbus Sans L", Roboto, Noto, "Segoe UI", Arial, Helvetica,
		"Helvetica Neue", sans-serif;
	--mono-font: Consolas, Menlo, Monaco, "Andale Mono", "Ubuntu Mono", monospace;

	--background: #121212;
	--foreground: #282828;
	--white: #FFFFFF;
	--grey: #B3B3B3;
	--accent: #57B65F;
	--shadow: 0px 4px 4px rgba(51, 51, 51, 0.15), 0px 0px 4px rgba(0, 0, 0, 0.25);

	--red: #FF5353;
	--green: #85DC83;
	--blue: #2B8CF7;
	--orange: #FFA653;
	--yellow: #FBE205;
	--border: #d8dae1;
	--disabled: #EFEFEF;
}

* {
	box-sizing: border-box;
	min-width: 0;
	scroll-behavior: smooth;
	user-select: none;
	/*  */
	overflow: -moz-scrollbars-none !important;
	scrollbar-width: none !important;
	-ms-overflow-style: none !important;
}

html, body {
	position: relative;
	margin: 0;
	padding: 0;
	display: flex;
	background-color: var(--background);
	color: var(--white);
	width: 100%;
	height: 100%;
}

html, body{
	overflow: -moz-scrollbars-none !important;
	scrollbar-width: none !important;
	-ms-overflow-style: none !important;
}
*::-webkit-scrollbar { 
	width: 0 !important;
	display: none !important;
}
html::-webkit-scrollbar, body::-webkit-scrollbar{ 
	width: 0 !important;
	display: none !important;
}

/*background-color: #282c34;*/
/*color: #61dafb;*/

#root {
	position: relative;
    /*z-index: -1;*/
	margin: auto;
	width: 100%;
	max-width: 430px;
	/*max-height: 880px;*/
	overflow-y: scroll;
	background-color: var(--background);
}

main {
	position: relative;
	margin: 0 16px;
	padding: 8px 0 160px;
	/*overflow: hidden;*/
	width: calc(100vw  - 32px);
}

footer {
	position: fixed;
	/*position: sticky;*/
	bottom: 0;
	left: 0;
	margin: 0;
	padding: 4px 8px;
	
	width: 100%;
	height: 56px;
	
	background-color: var(--background);
	font-size: 14px;
}

footer .footer {
	display: flex;
	height: 48px;
}
footer .footer span {
	display: flex;
	flex: 1 1 30%;
	width: 100px;
	height: 100%;
}
footer .footer span svg {
	margin: auto;
}