.bg-gradient {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 240px;
	overflow: hidden;
}
.bg-gradient video {
	position: relative;
	width: 100%;
	height: auto;
}
.bg-gradient img {
	position: absolute;
    /*bottom: 0;*/
    bottom: 0%;
    left: 0;
    width: 100%;
    /*height: auto;*/
    height: 100%;
}

/*  */

.section-header {
	margin: 16px 0;
}
.section-header * {
	margin: 0;
}
.section-header.single-line {
	/* nothing */
}
.section-header.multi-line {
	display: flex;
	gap: 16px;
	padding-top: 24px;
}
.section-header.multi-line h4 {
	color: var(--grey);
	margin: 4px 0;
	font-weight: 500;
}
.section-header img {
	border-radius: 100%;
	width: 64px;
	height: 64px;
}

.card-wrapper {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
	gap: 16px;
}
.card-wrapper.overflow--snap-scroll {
	display: flex;
	grid-template-columns: unset;
	gap: 16px;
	width: auto;
	max-width: none;
	overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    scrollbar-width: none;
	margin: 0 -16px;
	padding: 0 16px;
}
.card-wrapper.overflow--snap-scroll::-webkit-scrollbar {
	display: none;
}
.card-wrapper.overflow--snap-scroll > div {
	/*scroll-snap-align: start;*/
	min-width: 144px;
}
.card {
	background-color: var(--foreground);
	border-radius: 8px;
	box-shadow: var(--shadow);
}
.recent-playlists {
	/*  */
}
.recent-playlist {
	display: flex;
	flex-wrap: nowrap;
	gap: 8px;
	height: 56px;
	overflow: hidden;
	transition-duration: .2s;
}
.recent-playlist:hover {
	cursor: pointer;
	opacity: .8;
	transition-duration: .2s;
}
.recent-playlist img {
	width: 56px;
	height: 56px;
}
.recent-playlist span {
	font-size: 14px;
	line-height: 56px;
	/*font-family: 'Circular Spotify Bold';*/
	font-weight: bold;
}
.recommended-albums {
	/*  */
}
.recommended-album {
	width: 144px;
	max-width: 144px;
	transition-duration: .2s;
}
.recommended-album:hover {
	cursor: pointer;
	opacity: .8;
	transition-duration: .2s;
}
.recommended-album img {
	/*width: 112px;*/
	/*height: 112px;*/
	width: auto;
	max-width: 100%;
	height: auto;
	margin-bottom: 12px;
}
.recommended-album span {
	font-weight: bold;
}

.info-section{
	padding: 16px;
	width: 100%;
	height: auto;
}
.info-section span{
	font-size: 14px;
	line-height: 20px;
	color: var(--grey);
}

.scram-message button {
	appearance: none;
	border: none;
	outline: none;
	background-color: var(--accent);
	border-radius: 8px;
	padding: 12px 24px;
	width: 100%;
	max-width: 100%;
	margin: 0;
	font-size: 14px;
	color: var(--background);
	font-weight: bold;
	transition-duration: .2s;
}
button:hover {
	opacity: .8;
	cursor: pointer;
	transition-duration: .2s;
}

.scram-message img {
	width: 100%;
	max-width: 100%;
	margin-bottom: 16px;
}

.home-header-content {
	display: flex;
	flex-wrap: nowrap;
	gap: 8px
}
.home-header-content > * {
	padding: 0px 16px;
	line-height: 32px;
	/*  */
	border-radius: 32px;
	background-color: var(--foreground);
	color: var(--white);
	font-size: 12px;
	font-weight: 600;
}
.home-header-content > .active {
	/*background-color: var(--accent);*/
	background-color: #1ED760;
	color: var(--white);
}
.home-header-content .back-button {
	padding: 0;
	width: 32px;
	height: 32px;
	background-color: transparent;
}
.home-header-content .back-button svg {
	margin: 4px 2px
}
.playlist-header {}

.playlist-header img {
    width: 156px;
    height: auto;
    margin: 0 auto 16px;
    display: block;
    box-shadow: var(--shadow);
}
.playlist-details h1 {
    font-size: 32px;
    margin: 0 0 8px;
    line-height: 40px;
}
/*p.playlist-details-description {*/
.playlist-details p {
    margin: 0 0 8px;
    font-size: 14px;
    /*color: #6a6a6a;*/
    color: #a7a7a7;
    line-height: normal;
}
.playlist-details p:nth-child(3) {
    /*color: #6a6a6a;*/
    /*color: #a7a7a7;*/
    color: #FFFFFF;
}
.playlist-details p:nth-child(4) {
    /*color: #6a6a6a;*/
    /*color: #a7a7a7;*/
    color: #FFFFFFB2;
}
.playlist-controls-wrapper {
    display: flex;
    gap: 0px;
    justify-content: space-between;
	margin: 16px 0 32px;
}

.playlist-controls {
    display: flex;
    gap: 24px;
    justify-content: left;
}

.single-playlist-control {
    height: 56px;
    /*width: 56px;*/
    /*background-color: var(--foreground);*/
	display: flex;
}
.single-playlist-control svg {
	width: 24px;
	fill: #6a6a6a;
	margin: auto;
}
.single-playlist-control.play img {
    height: 56px;
    width: 56px;
}

.track-list {
    display: flex;
    flex-direction: column;
	margin: 0px -4px;
}

.track {
    width: 100%;
}

.track-card {
    display: flex;
	flex-wrap: nowrap;
	gap: 16px;
	justify-content: left;
	width: 100%;
}
.track-card .actions {
    margin: 0 0 0 auto;
    padding: 12px;
	width: 48px;
    height: 48px;
    transform: rotate(90deg);
}
.track-card img {
    width: 48px;
    height: 48px;
    border-radius: 2px;
}

.track-info p {
    margin: 4px 0;
    font-size: 13px;
    color: #6a6a6a;
}
.track-info p:nth-child(1) {
    font-size: 16px;
    color: var(--white);
}

.track-notes {
    width: 100%;
    background-color: var(--foreground);
	border-radius: 8px;
	margin: 8px 0;
	padding: 8px;
}
.track-notes p {
    margin: 4px 0;
    font-size: 16px;
    color: var(--grey);
}